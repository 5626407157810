import { Statistiques } from '../interfaces/statistiques.interface';

export interface StatistiquesState {
  selectedYear: number;
  statistiquesData: Statistiques[];
}

export const initialStatistiquesState: StatistiquesState = {
  selectedYear: new Date().getFullYear(),
  statistiquesData: [],
};
