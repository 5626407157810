import { createReducer, on } from '@ngrx/store';

import {
  loadStatistiquesDataSuccess,
  loadStatistiquesSelectedYear,
} from './statistiques.actions';
import {
  initialStatistiquesState,
  StatistiquesState,
} from './statistiques.state';

export const statistiquesReducer = createReducer<StatistiquesState>(
  initialStatistiquesState,
  on(loadStatistiquesDataSuccess, (state, { statistiquesData }) => ({
    ...state,
    statistiquesData,
  })),
  on(loadStatistiquesSelectedYear, (state, { selectedYear }) => ({
    ...state,
    selectedYear,
  }))
);
