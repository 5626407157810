import { createFeatureSelector, createSelector } from '@ngrx/store';

import { StatistiquesState } from './statistiques.state';

const getStatistiquesState =
  createFeatureSelector<StatistiquesState>('statistiques');

export const getSelectedYear = createSelector(
  getStatistiquesState,
  (state: StatistiquesState) => state?.selectedYear
);

export const getStatistiquesData = createSelector(
  getStatistiquesState,
  (state: StatistiquesState) => state?.statistiquesData
);
