import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import saveAs from 'file-saver';
import { take } from 'rxjs/operators';
import { ConfigKeys } from '../../../../shared/interfaces/config.interface';
import { ConfigService } from '../../../../shared/services/config.service';
import { getVersionDetails } from '../../home/store/home.selectors';

@Injectable({
  providedIn: 'root'
})
export class ProductReferentialService {
  private basePath = 'http://localhost:3333/api';

  constructor(public httpClient: HttpClient, private readonly store$: Store<any>, private readonly config: ConfigService) {
    this.basePath = config.get(ConfigKeys.API_BASE_PATH);
  }

  public async downloadProductReferential(): Promise<void> {
    const url = `${this.basePath}/referentiel-produits/download-product-repository`;

    const response = await this.httpClient.get(url, { responseType: 'text' }).toPromise();

    const versionDetails = this.store$.select(getVersionDetails).pipe(take(1)).toPromise();

    const fileName = `Référentiel v${(await versionDetails).version}.xlsx`;

    const arrayBuffer = this._base64ToArrayBuffer(response);
    const blob = new Blob([arrayBuffer], { type: 'octet/stream' });
    saveAs(blob, fileName);
  }

  private _base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
}
