import { Injectable } from '@angular/core';
import { ITcRestApiRoutesProvider, RestApiRoutes } from '@tc/rest-api';

@Injectable({
  providedIn: 'root',
})
export class AmsRouteProvider extends ITcRestApiRoutesProvider {
  public getRoutes(): RestApiRoutes {
    return {
      userProfile: 'users/me-utilisateur',
      associations: 'associations',
      associationsWithDeliveryNodes: 'associations/get-associations-with-delivery-notes',
      banques: 'banques',
      bonsLivraison: 'bons-livraison',
      noOfAssociationsPerCategory: 'associations/get-nb-assoc-categorie',
      noOfAssociationsEquipped: 'associations/get-nb-assoc-equipe',
      noOfAssociationsNotUsingTicadi:
        'associations/get-nb-assoc-non-utilisant-ticadi',
      articles: 'articles',
      bonsLivraisonRecu: 'bons-livraison/list-bons-livraison-recu',
      listArticlesRecu: 'articles/get-list-articles-recu',
      nbAssocViaOutilTrimestre: 'associations/get-nb-assoc-via-outil-trimestre',
      nbAssocViaOutil: 'associations/get-nb-assoc-via-outil',
      nbAssocAyant: 'associations/get-nb-assoc-ayant',
      nbAssocIndValides: 'associations/get-nb-assoc-valides',
      nbAssociationsIndNonRecus: 'associations/get-nb-assoc-non-recus',
      indicatorsGrid: 'indicateurs/get-indicateuers-etat-by-ba-grid-data',
    };
  }
}
