import { createAction, props } from '@ngrx/store';
import { TcFilterDef } from '@tc/abstract';

import { Statistiques } from '../interfaces/statistiques.interface';

export const loadStatistiquesData = createAction(
  '[Statistiques] Load Statistiques Data',
  props<{ statistiquesFilter: TcFilterDef }>()
);

export const loadStatistiquesSelectedYear = createAction(
  '[Statistiques] Load Selected Year',
  props<{ selectedYear: number }>()
);

export const loadStatistiquesDataSuccess = createAction(
  '[Statistiques] Load Statistiques Data Success',
  props<{ statistiquesData: Statistiques[] }>()
);

export const loadStatistiquesVersions = createAction(
  '[Statistiques] Load Statistiques Version Data',
  props<{ banqueId: number }>()
);
